<template>
  <nav
    class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-purple shadow"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <a
          class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
        >
          Sistema de consultas
        </a>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="setNavbarOpen"
        >
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center"
        :class="[navbarOpen ? 'block' : 'hidden']"
        id="example-navbar-warning"
      >
        <ul class="flex flex-col lg:flex-row list-none mr-auto">
          <router-link
            class="hover:border-with"
            v-if="
              userLogged == 'true' &&
              (permission.includes('acor') || permission.includes('admin'))
            "
            @click="navbarOpen = false"
            to="/acor/index"
          >
            <li class="flex items-center">
              <a
                class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold"
              >
                <i class="text-blueGray-400 far text-lg leading-lg" />
                Autos Acor
              </a>
            </li>
          </router-link>
          <router-link
            class="hover:border-with"
            v-if="
              userLogged == 'true' &&
              (permission.includes('tractas') || permission.includes('admin'))
            "
            @click="navbarOpen = false"
            to="/tractas/index"
          >
            <li class="flex items-center">
              <a
                class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold"
              >
                <i class="text-blueGray-400 far text-lg leading-lg" />
                Transporte
              </a>
            </li>
          </router-link>
          <router-link
            class="hover:border-with"
            v-if="
              userLogged == 'true' &&
              (permission.includes('sim') || permission.includes('admin'))
            "
            @click="navbarOpen = false"
            to="/sim/index"
          >
            <li class="flex items-center">
              <a
                class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold"
              >
                <i class="text-blueGray-400 far text-lg leading-lg" />
                Personas
              </a>
            </li>
          </router-link>
          <router-link
            class="hover:border-with"
            v-if="userLogged == 'true' && permission.includes('admin')"
            @click="navbarOpen = false"
            to="/auth/register"
          >
            <li class="flex items-center">
              <a
                class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold"
              >
                <i class="text-blueGray-400 far text-lg leading-lg" />
                Usuarios
              </a>
            </li>
          </router-link>
          <router-link
            class="hover:border-with"
            v-if="
              userLogged == 'true' &&
              (permission.includes('admin') || permission.includes('mapa'))
            "
            @click="navbarOpen = false"
            to="/mapa/maps"
          >
            <a
              class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold"
            >
              Mapa
            </a>
          </router-link>
          <router-link
            class="hover:border-with"
            v-if="
              userLogged == 'true' &&
              (permission.includes('admin') || permission.includes('mapa'))
            "
            @click="navbarOpen = false"
            to="/consultas/index"
          >
            <a
              class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold"
            >
              Consultas
            </a>
          </router-link>
          <router-link
            class="hover:border-with"
            v-if="
              userLogged == 'true' &&
              (permission.includes('admin') || permission.includes('mapa'))
            "
            @click="navbarOpen = false"
            to="/estadisticas/index"
          >
            <a
              class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold"
            >
              Estadisticas
            </a>
          </router-link>
          <router-link
            class="hover:border-with"
            v-if="
              userLogged == 'true' &&
              (permission.includes('admin') || permission.includes('comercio'))
            "
            @click="navbarOpen = false"
            to="/comercio/index"
          >
            <a
              class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold"
            >
              Comercio
            </a>
          </router-link>
        </ul>
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li class="flex items-center hover:border-with">
            <div>
              <router-link
                v-if="userLogged == 'true'"
                @click="navbarOpen = false"
                to="/"
              >
                <a
                  @click="logOut"
                  class="text-white px-3 py-2 flex items-center text-xs uppercase font-bold"
                >
                  Cerrar sesión
                </a>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script src="@/logic/scripts/authNavbar"></script>
