import axios from "axios";
import conf from "../logic/config";
axios.defaults.withCredentials = true;
export default {
  getAll(limit, offset) {
    return axios
      .get(`${conf.url}${conf.prefix}user/get-users/${limit}/${offset}`)
      .then((response) => response.data);
  },
  getAllAdmin(limit, offset) {
    return axios
      .get(`${conf.url}${conf.prefix}user/get-users-admin/${limit}/${offset}`)
      .then((response) => response.data);
  },
  getAgents() {
    return axios
      .get(`${conf.url}${conf.prefix}user/get-agents`)
      .then((response) => response.data);
  },
  getSupervisors() {
    return axios
      .get(`${conf.url}${conf.prefix}user/get-supervisors`)
      .then((response) => response.data);
  },
  getOne(userId) {
    return axios
      .post(`${conf.url}${conf.prefix}user/get-one`, {
        _id: userId,
      })
      .then((response) => response.data);
  },
  statusUser(userId, status) {
    return axios
      .put(`${conf.url}${conf.prefix}user/status-user`, {
        _id: userId,
        status: status,
      })
      .then((response) => response.data);
  },
  update(data) {
    return axios
      .put(`${conf.url}${conf.prefix}user/update`, {
        ...data,
      })
      .then((response) => response.data);
  },
  changePassword(userId, password, passwordConfirm) {
    return axios
      .post(`${conf.url}${conf.prefix}user/change-password`, {
        _id: userId,
        data: {
          password: password,
          passwordConfirmation: passwordConfirm,
        },
      })
      .then((response) => response.data);
  },
  getLogs(email, date) {
    return axios
      .post(`${conf.url}${conf.prefix}user/get-users-log`, {
        email: email,
        date: date,
      })
      .then((response) => response.data);
  },
};
