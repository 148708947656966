import Swal from "sweetalert2";
export default {
  async swallSuccess(mensaje) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    await Toast.fire({
      icon: "success",
      title: mensaje,
    });
  },
  swallError() {
    Swal.fire({
      icon: "error",
      title: "Disculpe se produjo un error",
      showConfirmButton: false,
      timer: 1500,
    });
  },
  swall(texto) {
    return Swal.fire({
      title: texto,
      width: 350,
      showCancelButton: true,
      cancelButtonText: "Cacelar",
      confirmButtonColor: "#c2236e",
      confirmButtonText: "Aceptar",
    }).then((result) => result);
  },
  miniSwall(mensaje) {
    Swal.fire({
      confirmButtonColor: "#c2236e",
      title: mensaje,
      width: 400,
    });
  },
  swallDinamic(mensaje) {
    return Swal.fire({
      confirmButtonColor: "#c2236e",
      width: 400,
      html: `<h2 style="display: block;font-size: 15px; text-align: justify; font-weight: 600">${mensaje}</h2>`,
      focusConfirm: false,
    });
  },
  swallPassword() {
    return Swal.fire({
      title: "Complete al menos uno de los campos por los que desea buscar un comercio.",
      confirmButtonColor: "#c2236e",
      confirmButtonText: "Buscar",
      width: 600,
      html:
        "<p> Nombre fantasía </p>" +
        '<input type="text" id="nombre_fantasia" class="swal2-input" placeholder="Campo opcional" style="width:70%;">' +
        "<p> Razón social </p>" +
        '<input type="text" id="razsoc" class="swal2-input" placeholder="Campo opcional" style="width:70%">' +
        "<p> Objeto </p>" +
        '<input type="text" id="objeto" class="swal2-input" placeholder="Campo opcional" style="width:70%">' +
        "<p> Titular </p>" +
        '<input type="text" id="titular" class="swal2-input" placeholder="Campo opcional" style="width:70%">' +
        "<p> Domicilio Comercial Real </p>" +
        '<input type="text" id="dom_comercial_real" class="swal2-input" placeholder="Campo opcional" style="width:70%">' +
        "<p> Domicilio Comercial Postal </p>" +
        '<input type="text" id="dom_comercial_postal" class="swal2-input" placeholder="Campo opcional" style="width:70%">' +
        "<p> Expediente </p>" +
        '<input type="text" id="expediente" class="swal2-input" placeholder="Campo opcional" style="width:70%">' +
        "<p> Tipo de búsqueda </p>" +
        '<select name="select" id="conector" style="width: 70%; border: 1px solid #d9d9d9;margin:1em 2em 3px; "><option value="and" >Incluyente (And)</option><option value="or">Excluyente (Or)</option></select>',
      focusConfirm: false,
      preConfirm: async () => {
        if (
          !document.getElementById("nombre_fantasia").value &&
          !document.getElementById("razsoc").value &&
          !document.getElementById("objeto").value &&
          !document.getElementById("titular").value &&
          !document.getElementById("dom_comercial_real").value &&
          !document.getElementById("dom_comercial_postal").value &&
          !document.getElementById("expediente").value
        ) {
          this.miniSwall(`Debe de completar al menos un campo.`);
          return;
        } else {
          const availableId = [
            "nombre_fantasia",
            "razsoc",
            "objeto",
            "titular",
            "dom_comercial_real",
            "dom_comercial_postal",
            "expediente",
            "conector"
          ];
          let querySearch = {};
          for (let index = 0; index < availableId.length; index++) {
            if (document.getElementById(availableId[index]).value) {
              let key = availableId[index];
              querySearch[key] = document.getElementById(availableId[index]).value
            }
          }
          return querySearch;
        }
      },
    });
  },
};
