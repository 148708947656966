import axios from "axios";
import Cookies from "js-cookie";
import conf from "@/logic/config/index.js";
axios.defaults.withCredentials = true;
export default {
  setUserLogged(submitted) {
    Cookies.set("u_l", submitted);
  },
  getUserLogged() {
    return Cookies.get("u_l");
  },
  setPermission(permission) {
    Cookies.set("u_p", permission);
  },
  getPermission() {
    return Cookies.get("u_p");
  },
  deletePermission() {
    Cookies.remove("u_p");
  },
  deleteUserLogged() {
    Cookies.remove("u_l");
  },
  setLocation(latitud, longitud) {
    Cookies.set("la_u", latitud);
    Cookies.set("ln_u", longitud);
  },
  getLocation() {
    return { latitud: Cookies.get("la_u"), longitud: Cookies.get("ln_u") };
  },
  removeLocation() {
    Cookies.remove("la_u");
    Cookies.remove("ln_u");
  },
  register(
    nombre,
    apellido,
    area,
    celular,
    email,
    password,
    passwordConfirmation,
    permission,
    staff,
    userType
  ) {
    return axios
      .post(`${conf.url}${conf.prefix}auth/signup`, {
        firstname: nombre,
        lastname: apellido,
        phone: celular,
        area: area,
        email: email,
        password: password,
        passwordConfirmation: passwordConfirmation,
        permission: permission,
        staff: staff,
        userType: userType,
      })
      .then((response) => {
        return { success: response };
      })
      .catch((error) => {
        return { error: error.response.data.error };
      });
  },
  async login(email, password) {
    return await axios
      .post(`${conf.url}${conf.prefix}auth/signin`, {
        email: email,
        password: password,
      })
      .then(async (response) => {
        const boolean = new Boolean(true);
        this.setUserLogged(boolean);
        await this.me();
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  logOut() {
    const boolean = new Boolean(false);
    this.setUserLogged(boolean);
    return axios
      .get(`${conf.url}${conf.prefix}auth/logout`, {
        withCredentials: true,
      })
      .then(async (response) => {
        this.deleteUserLogged();
        this.deletePermission();
        this.removeLocation();
      })
  },
  async me() {
    return await axios
      .get(`${conf.url}${conf.prefix}user/me`)
      .then((response) => {
        const permission = JSON.stringify(response.data.permission);
        this.setPermission(permission);
      });
  },
  location(latitud, longitud) {
    this.setLocation(latitud, longitud);
  },
  async reset() {
    const boolean = new Boolean(false);
    await this.setUserLogged(boolean);
    await this.deleteUserLogged();
    await this.deletePermission();
    await this.removeLocation();
  },
};
