import Multiselect from "@vueform/multiselect";
import auth from "@/logic/auth.ts";
import admin from "@/logic/admin.ts";
import swallHelper from "@/logic/helpers/swall";
import * as statusUser from "@/logic/helpers/funtions";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            value: null,
            agent: null,
            userType: null,
            edit: false,
            changePassword: false,
            options: ["admin", "acor", "sim", "tractas", "mapa", "comercio"],
            optionsUserType: ["supervisor", "agente"],
            optionsAgents: [],
            selectedOptions: { type: Array },
            userId: "",
            submited: false,
            errors: [],
            users: {
                email: "",
                permission: [],
                isActive: "",
                _id: "",
            },
            user: {
                _id: "",
                firstname: "",
                lastname: "",
                area: "",
                phone: "",
                email: "",
                password: "",
                passwordComfirm: "",
                permission: [],
                staff: [],
                userType: "",
            },
            agents: [],
            page: 1,
            perPage: 10,
            pages: [],
            countDocuments: "",
        };
    },
    methods: {
        async onChange(event) {
            this.user.permission = [];
            this.errors = [];
            if (event.includes("admin") && event.length > 1) {
                await swallHelper.swallDinamic(
                    "El usuario no puede tener rol admin junto con otros roles"
                );
                this.value = ["admin"];
                this.user.permission.push("admin");
            } else {
                this.user.permission.push(...event);
            }
        },
        async onChangeAgent(event) {
            this.errors = [];
            this.user.staff = [];
            this.agents.filter((agent) => {
                if (event.includes(agent.email)) {
                    this.user.staff.push(agent._id);
                }
            });
        },
        async onChangeUserType(event) {
            if (event.length > 1) {
                await swallHelper.swallDinamic(
                    "El usuario solo puede ser de un solo tipo."
                );
                this.userType = [];
            } else {
                this.user.userType = event[0];
            }
        },
        validateEmail(event) {
            this.errors = [];
            let regexEmail =
                /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
            if (regexEmail.test(this.user.email)) {
                this.errors = [];
            } else {
                return this.errors.push("Debe de  ingresar un email válido");
            }
        },
        isLetter(text) {
            let char = String.fromCharCode(text.keyCode);
            if (/^[A-Za-z\s]+$/.test(char)) return true;
            else text.preventDefault();
        },
        clearFields(submited = false) {
            this.user.email = "";
            this.user.password = "";
            this.user.passwordComfirm = "";
            this.user.firstname = "";
            this.user.lastname = "";
            this.user.area = "";
            this.user.phone = "";
            this.permission = [];
            this.staff = [];
            this.userType = "";
            this.submited = submited;
        },
        validator() {
            if (!this.user.firstname ||
                !this.user.lastname ||
                !this.user.area ||
                !this.user.phone ||
                !this.user.email ||
                !this.user.password ||
                !this.user.passwordComfirm
            ) {
                return this.errors.push("Debe de completar todos los campos");
            }
            if (this.user.userType == "agente" && this.user.staff.length) {
                return this.errors.push(
                    "Un usuario de tipo agente no puede tener personas a cargo"
                );
            }
            if (
                this.user.userType == "agente" &&
                this.user.permission.includes("mapa")
            ) {
                return this.errors.push(
                    "Un usuario de tipo agente no puede visualizar el mapa o consultas realizadas"
                );
            }
        },
        async getOne(userId) {
            try {
                const data = await admin.getOne(userId);
                this.userId = data._id;
                this.user = data;
                this.value = data.permission;
                const emailStaff = [];
                const userType = [];
                userType.push(data.userType);
                data.staff.forEach((staff) => emailStaff.push(staff.email));
                this.agent = emailStaff;
                this.userType = userType;
                this.edit = true;
                window.scrollTo(0, 0);
            } catch (err) {
                await statusUser.refresh(err.response.status);
            }
        },
        async query() {
            try {
                this.errors = [];
                this.submited = false;
                if (this.validator()) {
                    return;
                }
                await this.validateEmail(this.user.email);
                if (this.errors.length) {
                    return;
                }
                const authData = await auth.register(
                    this.user.firstname,
                    this.user.lastname,
                    this.user.area,
                    this.user.phone,
                    this.user.email,
                    this.user.password,
                    this.user.passwordComfirm,
                    this.user.permission,
                    this.user.staff,
                    this.user.userType
                );
                this.clearFields(true);
                location.reload();
            } catch (err) {
                await statusUser.refresh(err.response.status);
                return this.errors.push(
                    "No se especificaron correctamente los parametros."
                );
            }
        },
        async update() {
            try {
                this.submitEdit = false;
                this.errors = [];
                if (this.user.password && this.user.passwordComfirm) {
                    if (this.user.password == this.user.passwordComfirm) {
                        const data = await admin.changePassword(
                            this.userId,
                            this.user.password,
                            this.user.passwordComfirm
                        );
                    } else {
                        return this.errors.push("Las contraseñas no coinciden");
                    }
                }
                if (this.user.userType == "agente" && this.user.staff.length) {
                    return this.errors.push(
                        "Un usuario de tipo agente no puede tener personas a cargo"
                    );
                }
                const update = await admin.update(this.user);
                if (update) {
                    this.user = update;
                    this.clearFields();
                    swallHelper.swallSuccess("Usuario Actualizado con éxito");
                    location.reload();
                } else {
                    swallHelper.swallError();
                    location.reload();
                }
            } catch (err) {
                await statusUser.refresh(err.response.status);
                return this.errors.push(
                    "No se especificaron correctamente los parametros."
                );
            }
        },
        async getAll(page = 1) {
            try {
                page = page - 1;
                const getData = await admin.getAllAdmin(this.perPage, page);
                this.users = getData.data;
                this.countDocuments = getData.countDocuments;
                this.setUsers();
                window.scrollTo(0, 500);
            } catch (err) {
                await statusUser.refresh(err.response.status);
            }
        },
        setUsers() {
            let numberOfPages = Math.ceil(this.countDocuments / this.perPage);
            this.pages = [];
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        },
        async statusUser(status) {
            try {
                const result = await swallHelper.swall("Esta seguro?");
                if (result.isConfirmed) {
                    const disable = await admin.statusUser(this.userId, status);
                    if (disable["error"]) {
                        this.clearFields();
                        return this.errors.push(disable.error);
                    }
                    this.clearFields(false);
                    await swallHelper.swallSuccess("Estado actualizado con éxito");
                    location.reload();
                }
            } catch (err) {
                await statusUser.refresh(err.response.status);
                swallHelper.swallError();
            }
        },
        async getAgents() {
            try {
                this.agents = await admin.getAgents();
                this.optionsAgents = [];
                this.agents.forEach((agent) => {
                    this.optionsAgents.push(agent.email);
                });
            } catch (err) {
                await statusUser.refresh(err.response.status);
                return this.errors.push("Se produjo un error");
            }
        },
        async getSupervisors() {
            try {
                const agents = await admin.getSupervisors();
                agents.forEach((agent) => {
                    this.optionsAgents.push(agent.email);
                });
            } catch (error) {
                await statusUser.refresh(err.response.status);
                return this.errors.push("Se produjo un error");
            }
        },
    },
    created() {
        this.getAll();
        this.getAgents();
    },
};