import { ref } from "vue";
import swall from "../helpers/swall";
export default {
  props: {
    usersList: { type: Array },
  },
  data() {
    return {
      date: { type: Date },
      udpate: { type: Date },
      fechaFiltro: [],
    };
  },
  methods: {
    onChange(event) {
      this.$emit("changeValue", event.target.value);
    },
    async handleDate(modelData) {
      if (!modelData) {
        return;
      } else if (Object.keys(modelData).length == 2) {
        for (const iterator of Object.values(modelData)) {
          let fullDate = new Date(iterator);
          // let year = fullDate.getFullYear();
          // let month = fullDate.getMonth();
          // let date = fullDate.getDate();
          // let hours = fullDate.getHours();
          // let minits = fullDate.getMinutes();
          // const fecha = new Date(
          //   Date.UTC(year, month, date, hours, minits)
          // ).toISOString();
          this.fechaFiltro.push(fullDate);
        }
        this.$emit("timeFilter", this.fechaFiltro);
        this.date = this.fechaFiltro;
        this.fechaFiltro = [];
      }else if (Object.keys(modelData).length == 1) {
        let initDay = new Date(modelData[0])
        initDay.setHours(0,0,0,0)
        let endDay = new Date(modelData[0]) 
        endDay.setHours(23,59,59,59)
        this.fechaFiltro.push(initDay, endDay)
        this.$emit("timeFilter", this.fechaFiltro);
        this.date = this.fechaFiltro;
        this.fechaFiltro = [];
      } else {
        this.date = null;
        swall.swallDinamic("Debe de ingresar al menos dos rangos de fechas.");
      }
    },
  },
  setup() {
    const date = ref();

    return {
      date,
    };
  },
};
