<template>
  <div>
    <navbar />
    <main>
      <section class="relative w-full h-full py-10 min-h-screen">
        <div
          class="absolute top-0 w-full h-full  bg-no-repeat bg-full"
        ></div>
        <router-view /> 
      <footer-component />
      </section>
    </main>
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

import registerBg2 from "@/assets/img/register_bg_2.png";

export default {
  data() {
    return {
      registerBg2,
    };
  },
  components: {
    Navbar,
    FooterComponent,
  },
};
</script>
