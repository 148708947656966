import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import "@vuepic/vue-datepicker/dist/main.css";
import App from "@/App.vue";
import Datepicker from "@vuepic/vue-datepicker";
import routes from "@/router/routes"

const router = createRouter({
    history: createWebHistory(),
    routes,
});
const app = createApp(App);
app.component("Datepicker", Datepicker);
app.use(router);
app.mount("#app");