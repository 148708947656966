<template>
  <div>
    <navbar />
    <main>
      <section class="relative w-full h-full py-10 min-h-screen">
        <div :style="image"
          class="fixed top-0 w-full h-full bg-center bg-cover"
        > 
          <span
            id="blackOverlay"
            class="w-full h-full fixed opacity-75 bg-black"
          ></span>
        </div>
        <router-view />
        <footer-component />
      </section>
    </main>
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

export default {
  components: {
    Navbar,
    FooterComponent,
  },
  computed: {
    image(){
      return `background-image: url(${(require('../assets/palacio.png'))})`
    }
  }
};
</script>