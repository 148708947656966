<template>
  <!-- Header -->
  <section class="relative w-full h-full py-10">
    <div class="container mx-auto px-4 h-full">
      <div
        class="flex content-center items-center justify-center h-full margin-bottom"
      >
        <div class="w-full px-4">
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
          >
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div class="text-blueGray-400 text-center mb-3 font-bold"></div>
              <div>
                <h3 class="ml-3 mt-3 mb-4" style="font-weight: 600">
                  <p>
                    A continuación podra visualizar estadísticas de usuarios que
                    dependen de usted, pudiendo filtrarlas por fecha o
                    visuandolas todas en el historial.
                  </p>
                </h3>
                <!-- Card stats -->
                <div class="flex flex-wrap">
                  <div class="mt-4 w-full lg:w-6/12 xl:w-3/12 px-4">
                    <datepicker
                      v-model="date"
                      multiDates
                      @update:modelValue="handleDate"
                    >
                    </datepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="submitted" class="container">
            <div class="row">
              <div class="col">
                <div
                  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
                >
                  <div
                    class="bg-blueGray-200 rounded-t mb-0 px-4 py-3 border-0 text-center"
                  >
                    <div class="flex flex-wrap items-center">
                      <div
                        class="relative w-full px-4 max-w-full flex-grow flex-1"
                      >
                        <h3 class="font-semibold text-lg">
                          Estadísticas por usuario
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="block w-full overflow-x-auto">
                    <!-- Projects table -->
                    <table
                      class="items-center w-full bg-transparent border-collapse"
                    >
                      <thead style="height: 50px">
                        <tr>
                          <th
                            class="border-t-0 align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
                          ></th>
                          <th
                            class="table-width border-t-0 align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
                            colspan="2"
                          >
                            ACOR
                          </th>
                          <th
                            class="table-width border-t-0 align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
                            colspan="2"
                          >
                            Tractas
                          </th>
                          <th
                            class="table-width border-t-0 align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
                            colspan="2"
                          >
                            SIM
                          </th>
                          <th
                            class="table-width border-t-0 align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
                            colspan="2"
                          >
                            Comercio
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          ></td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            Encontrados
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            No encontrados
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            Encontrados
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            No encontrados
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            Encontrados
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            No encontrados
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            Encontrados
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            No encontrados
                          </td>
                        </tr>
                        <tr v-for="(data, index) in estadisticas" :key="index">
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ data.usuario.firstname }}
                            {{ data.usuario.lastname }}
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ data.statistics[0].acorEncontrados }}
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ data.statistics[0].acorNoEncontrados }}
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ data.statistics[0].tractasEncontrados }}
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ data.statistics[0].tractasNoEncontrados }}
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ data.statistics[0].simEncontrados }}
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ data.statistics[0].simNoEncontrados }}
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ data.statistics[0].comercioEncontrados }}
                          </td>
                          <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ data.statistics[0].comercioNoEncontrados }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="py-2" style="padding-left: 2%">
                      <nav class="block">
                        <ul
                          class="flex pl-0 rounded list-none flex-wrap"
                          v-if="page <= pages.length"
                        >
                          <li>
                            <a
                              v-if="page != 1"
                              @click="getData(date, (page = 1))"
                              href="#"
                              class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blueGray-500 bg-blueGray-500"
                            >
                              1
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              v-if="page != 1 && pages.length != 2"
                              @click="getData(date, (page = page - 1))"
                              class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blueGray-500 bg-blueGray-500"
                            >
                              &lt &lt
                            </a>
                          </li>
                          <li>
                            <a
                              class="first:ml-0 text-xs flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blueGray-500 text-white bg-purple"
                            >
                              {{ page }}
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              v-if="page != pages.length && pages.length != 2"
                              @click="getData(date, (page = page + 1))"
                              class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blueGray-500 bg-blueGray-500"
                            >
                              >>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              v-if="page != pages.length"
                              @click="getData(date, (page = pages.length))"
                              class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-blueGray-500 bg-blueGray-500"
                            >
                              {{ pages.length }}</a
                            >
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script src="@/logic/scripts/estadisticas"></script>
