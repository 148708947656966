<template>
  <footer class="relative bg-blueGray-200 pt-8 pb-6" style="margin-bottom: -100%; margin-top: 24%">
    <div
      class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
      style="transform: translateZ(0)"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-blueGray-200 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-center lg:text-left">
        <div class="w-full lg:w-6/12 px-4">
          <h5 class="text-lg mt-0 mb-2 text-blueGray-600">
            Redes sociales de la Municipalidad de la Ciudad de Corrientes.
          </h5>
          <div class="mt-6 lg:mb-0 mb-6">
            <button
              class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <a href="https://twitter.com/municorrientes" target="_blank"
                ><i class="fab fa-twitter"></i
              ></a>
            </button>
            <button
              class="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <a href="https://www.facebook.com/corrientesmuni/" target="_blank"
                ><i class="fab fa-facebook-square"></i
              ></a>
            </button>
            <button
              class="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <a
                href="https://www.instagram.com/municorrientes/"
                target="_blank"
                ><i class="fab fa-instagram"></i
              ></a>
            </button>
            <button
              class="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <a
                href="https://www.youtube.com/channel/UCQIaXdSQ5qI3AUMItIErjjA"
                target="_blank"
                ><i class="fab fa-youtube" style="color: red"></i
              ></a>
            </button>
          </div>
        </div>
      </div>
      <hr class="my-6"/>
      <div
        class="row flex flex-wrap items-center md:justify-between justify-center"
      >
      <img src="@/assets/bottom-bg.png" style="width: 100%">
        <div class="col-md-6">
          <img
            src="@/assets/call_negro.png"
          />
        </div>
        <div class="col-md-6 ">
          <img
            src="@/assets/direccion_negro.png"
          />
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  }
};
</script>
