import moment from "moment-timezone";
import { ref } from "vue";
import estadisticas from "@/logic/estadisticas.ts";
import { refresh } from "../helpers/funtions";
import swall from "../helpers/swall";
export default {
  data() {
    return {
      fechaFiltro: [],
      submitted: false,
      estadisticas: {
        usuario: {
          firstname: { type: String },
          lastname: { type: String },
        },
        statistics: [
          {
            acorEncontrados: { type: Number },
            acorNoEncontrados: { type: Number },
            simEncontrados: { type: Number },
            simNoEncontrados: { type: Number },
            tractasEncontrados: { type: Number },
            tractasNoEncontrados: { type: Number },
          },
        ],
      },
      page: 1,
      perPage: 10,
      pages: [],
      countDocuments: "",
      fechas: [],
    };
  },
  methods: {
    onChange(event) {
      this.$emit("changeValue", event.target.value);
    },
    async getData(date, page = 1) {
      try {
        page = page - 1;
        if (date) {
          const data = await estadisticas.getStatistics(
            this.perPage,
            page,
            date
          );
          this.estadisticas = data.data;
          this.countDocuments = data.countDocuments;
          this.setStatistics();
        } else {
          const data = await estadisticas.getStatistics(this.perPage, page);
          this.estadisticas = data.data;
          this.countDocuments = data.countDocuments;
          this.setStatistics();
        }
        this.submitted = true;
      } catch (error) {
        if (error.response) {
          await refresh(error.response.status);
        }
      }
    },
    async handleDate(modelData) {
      if (!modelData) {
        return;
      } else if (Object.keys(modelData).length == 2) {
        this.page = 1;
        for (const iterator of Object.values(modelData)) {
          let fullDate = new Date(iterator);
          // let year = fullDate.getFullYear();
          // let month = fullDate.getMonth();
          // let date = fullDate.getDate();
          // let hours = fullDate.getHours();
          // let minits = fullDate.getMinutes();
          // let seconds = fullDate.getSeconds();
          // let miliseconds = fullDate.getMilliseconds();
          // const fecha = new Date(
          //   Date.UTC(year, month, date, hours, minits, seconds, miliseconds)
          // ).toISOString();
          this.fechaFiltro.push(fullDate);
        }
        await this.getData(this.fechaFiltro);
        this.fechaFiltro = [];
      } else if (Object.keys(modelData).length == 1) {
        this.page = 1;
        let initDay = new Date(modelData[0]);
        initDay.setHours(0, 0, 0, 0);
        let endDay = new Date(modelData[0]);
        endDay.setHours(23, 59, 59, 59);
        this.fechaFiltro.push(initDay, endDay);
        this.date = this.fechaFiltro;
        await this.getData(this.fechaFiltro);
        this.fechaFiltro = [];
      } else {
        this.date = null;
        swall.swallDinamic("Debe de ingresar como máximo dos rangos de fechas.");
      }
    },
    setStatistics() {
      let numberOfPages = Math.ceil(this.countDocuments / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
  },
  setup() {
    const date = ref();
    return {
      date,
    };
  },
  created() {
    this.getData();
    this.setStatistics();
  },
};
