import auth from "@/logic/auth.ts";
export default {
    data() {
        return {
            navbarOpen: false,
            user: [],
        };
    },
    computed: {
        userLogged() {
            return auth.getUserLogged();
        },
        permission() {
            return (this.user = JSON.parse(auth.getPermission() || ["null"]));
        },
    },
    methods: {
        async logOut() {
            try {
                await auth.logOut();
                location.reload();
            } catch (err) {
                this.err = true;
            }
        },
        setNavbarOpen: function() {
            this.navbarOpen = !this.navbarOpen;
        },
    },
};