<template>
  <section class="relative w-full h-full py-10">
    <div class="container mx-auto px-4 h-full">
      <div
        class="flex content-center items-center justify-center h-full margin-bottom"
      >
        <div class="w-full px-4">
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
          >
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div class="text-blueGray-400 text-center mb-3 font-bold"></div>
              <form @submit.prevent="query" >
                <div class="text-center mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Personas - Ingrese DNI o CUIT
                  </label>
                  <input
                    v-model="numero"
                    class="text-center border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    placeholder="DNI o CUIT"
                    required
                  />
                </div>

                <div class="text-center mb-4 mt-6">
                  <button
                    @click="query"
                    class="bg-purple text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    v-if="isActive == true"
                  >
                    Consultar
                  </button>
                  <p v-if="errors.length">
                      <ul>
                        <li v-for="(error, indexError) in errors" :key="indexError"><b>{{ error }}</b></li>
                      </ul>
                  </p>
                   <div v-if=" isVisible == true" id="app">
                      <LoadingBar/>
                    </div>
                </div>
              </form>
            </div>
          </div>
          <div v-if="submitted" class="container">
            <div class="row">
              <div class="col">
                <div  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
                >
                  <div class="bg-blueGray-200 rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                      <div
                        class="relative w-full px-4 max-w-full flex-grow flex-1"
                      >
                        <h3 class="font-semibold text-lg text-center">Persona</h3>
                      </div>
                    </div>
                  </div>
                  <div class="block w-full overflow-x-auto">
                    <!-- Projects table -->
                    <table
                      class="items-center w-full bg-transparent border-collapse"
                    >
                      <thead>
                        <tr>
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" style="border-top-width: 0px"
                          >
                            Tipo
                          </th>
                           <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
                          >
                            <span class="font-bold">
                              {{ items.persona_tipo }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Documento tipo
                          </th>
                           <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.documento_tipo }}
                          </td>
                         </tr>
                         <tr>
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Documento Número
                          </th>
                           <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.documento_nro }}
                          </td>
                         </tr>
                         <tr>
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Razón social
                          </th>
                           <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.nombre_raz_social }}
                          </td>
                          </tr>
                          <tr>
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Nacionalidad
                          </th>
                           <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.nacionalidad }}
                          </td>
                          </tr>
                          <tr>
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Ciudad nacimiento
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.ciudad_nacimiento }}
                          </td>
                          </tr>
                          <tr>
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Provincia nacimiento
                          </th>
                           <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.provincia_nacimiento }}
                          </td>
                          </tr>
                          <tr>
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Sexo
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.sexo_nacimiento }}
                          </td>
                          </tr>
                          <tr>
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Género
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.genero }}
                          </td>
                          </tr>
                          <tr>                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Fecha nacimiento
                          </th>
                              <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.fec_nac }}
                          </td>
                          </tr>
                          <tr>
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Empresa
                          </th>
                            <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.empresa_objetivo_principal }}
                          </td>
                          </tr>
                          <tr>
                          <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Estado Registro
                          </th>
                             <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.estado_del_registro }}
                          </td>
                          </tr>
                          <tr>
                          <th 
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                          Domicilio
                          </th>
                              <td v-for="(data, index) in items.domicilios"
                                :key="index"
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ data.otro_dato_domi }}
                          </td>
                          </tr>
                          <tr>
                            <th
                              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            >
                            Telefono
                            </th>
                            <td  v-for="(telefono, index) in items.telefonos"
                                :key="index"
                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ telefono.numero }}
                          </td>
                          </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script src="@/logic/scripts/sim">
</script>
