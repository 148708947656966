<template>
  <section>
    <div class="container py-5 mx-auto px-4 h-full" >
      <div
        class="flex content-center items-center justify-center h-full margin-bottom"
      >
        <div class="w-full lg:w-4/12 px-4" >
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
          >
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div class="text-blueGray-400 text-center mb-3 font-bold"></div>
              <form  v-on:keyup.enter="login">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email 
                  </label>
                  <input
                    type="email"
                    v-model="email"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                    required
                  />
                </div>

                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Contraseña
                  </label>
                  <input
                    type="password"
                    v-model="password"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Contraseña"
                    required
                  />
                </div>
                <div class="text-center mb-4 mt-6">
                  <button
                    @click="login"
                    class="bg-purple text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                  >
                    Iniciar sesión
                  </button>
                     <p v-if="errors.length">
                    <ul>
                      <li v-for="(error, indexError) in errors" :key="indexError"><b>{{ error }}</b></li>
                    </ul>
                </p>
                </div>
              </form>
            </div>
          </div>
          <div class="flex flex-wrap mt-6 relative">
            <div class="w-1/2">
              <button class="mt-4 text-lg text-blueGray-200 text-justify" @click="passowrdRecovery">
                <small style="font-weight: 600">Olvido su contraseña?</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script src="@/logic/scripts/login">
</script>
