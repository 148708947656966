import consultas from "@/logic/consultas.ts";
import { refresh } from "../helpers/funtions";
export default {
    data() {
        return {
            logs: [{
                usuario: {
                    firstname: "",
                    lastname: "",
                },
                sistema: "",
                parametro: "",
                fecha: "",
                resultado: "",
            }],
            page: 1,
            perPage: 100,
            pages: [],
            countDocuments: ''
        };
    },
    created() {
        this.getLogs();
        this.setLogs();
    },
    methods: {
        async getLogs(page = 1) {
            try {
                page = page - 1;
                const data = await consultas.getData(this.perPage, page);
                this.logs = data.data;
                this.countDocuments = data.countDocuments;
                this.setLogs();
            } catch (error) {
                if (error.response) {
                    await refresh(error.response.status);
                  }
            }
        },
        setLogs() {
            let numberOfPages = Math.ceil(this.countDocuments / this.perPage);
            this.pages = [];
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        },
    },
};