import AuthNavbar from "@/components/Navbars/AuthNavbar.vue";
import HeaderStats from "@/components/Headers/HeaderStats.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import admin from "@/logic/admin.ts";
import swall from "../helpers/swall";
import * as statusUser from "@/logic/helpers/funtions";
export default {
    name: "admin-layout",
    components: {
        AuthNavbar,
        HeaderStats,
        FooterComponent,
    },
    data() {
        return {
            users: [{
                _id: "",
                email: "",
            }, ],
            markers: [{
                lat: { type: Number },
                lng: { type: Number },
            }, ],
            centerMap: { lat: -27.468500137329, lng: -58.831298828125 },
            timesFilters: { type: Array }
        };
    },
    methods: {
        async handleChange(info) {
            try {
                this.changeValue = info;
                this.user = this.changeValue;
                if (this.timesFilters.length) {
                    const getLogs = await admin.getLogs(this.changeValue, this.timesFilters);
                    this.markers = getLogs;
                    this.map();
                } else {
                    const getLogs = await admin.getLogs(this.changeValue);
                    this.markers = getLogs;
                    this.map();
                }

            } catch (err) {
                await statusUser.refresh(err.response.status);
            }
        },
        async handleFilter(timeFilter) {
            try {
                this.timesFilters = timeFilter;
                if (this.user) {
                    const getLogs = await admin.getLogs(this.user, this.timesFilters);
                    this.markers = getLogs;
                    this.map();
                } else {
                    swall.swallDinamic('Debe de selecionar un usuario');
                }
                this.timesFilters = [];
            } catch (err) {
                await statusUser.refresh(err.response.status);
            }
        },
        map() {
            let google = window.google;
            let map = document.getElementById("map-canvas");
            let mapOptions = {
                zoom: 14,
                scrollwheel: false,
                center: this.centerMap,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [{
                        featureType: "administrative",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#444444" }],
                    },
                    {
                        featureType: "landscape",
                        elementType: "all",
                        stylers: [{ color: "#f2f2f2" }],
                    },
                    {
                        featureType: "poi",
                        elementType: "all",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "water",
                        elementType: "all",
                        stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
                    },
                ],
            };

            map = new google.maps.Map(map, mapOptions);
            this.markers.forEach((marker) => {
                new google.maps.Marker({
                    position: marker,
                    map: map,
                });
            });
        },
    },
    async mounted() {
        try {
            const userList = await admin.getAll(0, 0);
            this.users = userList.data;
            this.map();
        } catch (err) {
            await statusUser.refresh(err.response.status);
        }
    },
};