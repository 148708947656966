import auth from "@/logic/auth.ts";
import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";
import AuthLogin from "@/layouts/Login.vue";
import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import Acor from "@/views/acor/Index.vue";
import Sim from "@/views/sim/Index.vue";
import Tractas from "@/views/tractas/Index.vue";
import Consultas from "@/views/consultas/Index.vue";
import Estadisticas from "@/views/estadisticas/Index";
import Comercio from "@/views/comercio/Index"


const routes = [{
        path: "/mapa/",
        redirect: "/mapa/maps",
        component: Admin,
        name: "mapa",
        children: [{
            beforeEnter: async(to, from, next) => {
                const authController = await auth.getUserLogged();
                const permission = JSON.parse(
                    (await auth.getPermission()) || ["null"]
                );
                if (
                    authController == "true" &&
                    (permission.includes("admin") || permission.includes("mapa"))
                ) {
                    next();
                } else {
                    next({ name: "login" });
                }
            },
            path: "/mapa/maps",
        }, ],
    },
    {
        path: "/",
        redirect: "/auth/login",
        component: AuthLogin,
        name: "login",
        children: [{
            beforeEnter: async(to, from, next) => {
                const authController = await auth.getUserLogged();
                const permission = JSON.parse(
                    (await auth.getPermission()) || ["null"]
                );
                if (authController == "false" || !authController) {
                    next();
                } else if (authController == "true") {
                    next({ name: `${permission[0]}` });
                } else {
                    next({ name: "login" });
                }
            },
            path: "/auth/login",
            component: Login,
        }, ],
    },
    {
        path: "/auth/register",
        redirect: "/auth/register",
        component: Auth,
        name: "admin",
        children: [{
            beforeEnter: async(to, from, next) => {
                const authController = await auth.getUserLogged();
                const permission = JSON.parse(
                    (await auth.getPermission()) || ["null"]
                );
                if (authController == "true" && permission.includes("admin")) {
                    next();
                } else if (authController == "true") {
                    next({ name: `${permission[0]}` });
                } else {
                    next({ name: "login" });
                }
            },
            path: "/auth/register",
            component: Register,
        }, ],
    },
    {
        path: "/acor/",
        redirect: "/acor/index",
        component: Auth,
        name: "acor",
        children: [{
            beforeEnter: async(to, from, next) => {
                const authController = await auth.getUserLogged();
                const permission = JSON.parse(
                    (await auth.getPermission()) || ["null"]
                );
                if (
                    authController == "true" &&
                    (permission.includes("acor") || permission.includes("admin"))
                ) {
                    next();
                } else if (authController == "true") {
                    next({ name: `${permission[0]}` });
                } else {
                    next({ name: "login" });
                }
            },
            path: "/acor/index",
            component: Acor,
            name: "acor",
        }, ],
    },
    {
        path: "/tractas/",
        redirect: "/tractas/index",
        component: Auth,
        name: "tractas",
        children: [{
            beforeEnter: async(to, from, next) => {
                const authController = await auth.getUserLogged();
                const permission = JSON.parse(
                    (await auth.getPermission()) || ["null"]
                );
                if (
                    authController == "true" &&
                    (permission.includes("tractas") || permission.includes("admin"))
                ) {
                    next();
                } else if (authController == "true") {
                    next({ name: `${permission[0]}` });
                } else {
                    next({ name: "login" });
                }
            },
            path: "/tractas/index",
            component: Tractas,
            name: "tractas",
        }, ],
    },
    {
        path: "/sim/",
        redirect: "/sim/index",
        component: Auth,
        name: "sim",
        children: [{
            beforeEnter: async(to, from, next) => {
                const authController = await auth.getUserLogged();
                const permission = JSON.parse(
                    (await auth.getPermission()) || ["null"]
                );
                if (
                    authController == "true" &&
                    (permission.includes("sim") || permission.includes("admin"))
                ) {
                    next();
                } else if (authController == "true") {
                    next({ name: `${permission[0]}` });
                } else {
                    next({ name: "login" });
                }
            },
            path: "/sim/index",
            component: Sim,
            name: "sim",
        }, ],
    },
    {
        path: "/consultas/",
        redirect: "/consultas/index",
        component: Auth,
        name: "consultas",
        children: [{
            beforeEnter: async(to, from, next) => {
                const authController = await auth.getUserLogged();
                const permission = JSON.parse(
                    (await auth.getPermission()) || ["null"]
                );
                if (
                    authController == "true" &&
                    (permission.includes("mapa") || permission.includes("admin"))
                ) {
                    next();
                } else if (authController == "true") {
                    next({ name: `${permission[0]}` });
                } else {
                    next({ name: "login" });
                }
            },
            path: "/consultas/index",
            component: Consultas,
            name: "consultas",
        }, ],
    },
    {
        path: "/estadisticas/",
        redirect: "/estadisticas/index",
        component: Auth,
        name: "estadisticas",
        children: [{
            beforeEnter: async(to, from, next) => {
                const authController = await auth.getUserLogged();
                const permission = JSON.parse(
                    (await auth.getPermission()) || ["null"]
                );
                if (
                    authController == "true" &&
                    (permission.includes("mapa") || permission.includes("admin"))
                ) {
                    next();
                } else if (authController == "true") {
                    next({ name: `${permission[0]}` });
                } else {
                    next({ name: "login" });
                }
            },
            path: "/estadisticas/index",
            component: Estadisticas,
            name: "estadisticas",
        }, ],
    },
    {
        path: "/comercio/",
        redirect: "/comercio/index",
        component: Auth,
        name: "comercio",
        children: [{
            beforeEnter: async(to, from, next) => {
                const authController = await auth.getUserLogged();
                const permission = JSON.parse(
                    (await auth.getPermission()) || ["null"]
                );
                if (
                    authController == "true" &&
                    (permission.includes("comercio") || permission.includes("admin"))
                ) {
                    next();
                } else if (authController == "true") {
                    next({ name: `${permission[0]}` });
                } else {
                    next({ name: "login" });
                }
            },
            path: "/comercio/index",
            component: Comercio,
            name: "comercio",
        }, ],
    },
    {
        path: "/:pathMatch(.*)*",
        beforeEnter: async(to, from, next) => {
            const authController = await auth.getUserLogged();
            const permission = JSON.parse((await auth.getPermission()) || ["null"]);
            if (authController == "true") {
                next({ name: `${permission[0]}` });
            } else {
                next({ name: "login" });
            }
        },
    },
];

export default routes;