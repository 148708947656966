import axios from "axios";
import conf from "./config";
axios.defaults.withCredentials = true;

export default {
  getData(queryParams, latitud = undefined, longitud = undefined) {
    if (latitud != undefined && longitud != undefined) {
      return axios
        .post(
          `${conf.url}${conf.prefix}comercio`,
          {
            latitud: latitud,
            longitud: longitud,
          },
          {
            params: {
              ...queryParams,
            },
          }
        )
        .then((response) => response.data);
    } else {
      return axios
        .post(
          `${conf.url}${conf.prefix}comercio`,
          {},
          {
            params: {
              ...queryParams,
            },
          }
        )
        .then((response) => response.data);
    }
  },
};
