import axios from "axios";
import conf from "./config";
axios.defaults.withCredentials = true;

export default {
    getData(limit, offset){
        return axios
        .get(
          `${conf.url}${conf.prefix}user/get-queries/${limit}/${offset}`
        )
        .then((response) => response.data);
    }
}