import axios from "axios";
import conf from "./config";
axios.defaults.withCredentials = true;
export default {
  getStatistics(limit, offset, date) {
    return axios
      .post(`${conf.url}${conf.prefix}user/get-statistics/${limit}/${offset}`, {
        date: date,
      })
      .then((response) => response.data);
  },
};
