import LoadingBar from "@/components/LoadingBar.vue";
import tracta from "@/logic/tractas.ts";
import auth from "@/logic/auth.ts";
import * as statusUser from "@/logic/helpers/funtions";
export default {
  components: {
    LoadingBar,
  },
  data() {
    return {
      items: {
        habilitacion: [
          {
            num_hab: "",
            type: "",
            estado_hab: "",
            fec_hab: "",
            fec_vto_hab: "",
            periodo: "",
            nro_lic: "",
            nro_movil: "",
            nombre_fantasia: "",
            chofer: [
              {
                fechanac_ch: "",
                dni_ch: "",
                apyn_ch: "",
                domicilio_ch: "",
                tel_f_ch: "",
                cel_ch: "",
                correo_ch: "",
                sexo_ch: "",
                estado_chofer: "",
                num_cut: "",
                otoga_cut: "",
                vence_cut: "",
                seg_accidente_per: "",
                estado_cut: "",
              },
            ],
          },
        ],
        vehiculo: [
          {
            dominio_auto_local: "",
            ano_fab: "",
            modelo: "",
            marca: "",
            num_motor: "",
            num_chassis: "",
            color: "",
            capacidad: "",
            domicilio: "",
            nom_rev_tecnica: "",
            fec_vto_rto: "",
            fec_poliza_auto: "",
            est_vehi: "",
          },
        ],
        titular: [
          {
            tipo_doc: "",
            cuit: "",
            dni: "",
            apyn: "",
            domicilio_titular: "",
            barrio_titular: "",
            tel_titular: "",
          },
        ],
        infraccion: [
          {
            acta_nro: "",
            fecha_emi: "",
            lugar: "",
            inspector: "",
            infraccion: "",
            secuestro: "",
            dni_cond_infr: "",
            nom_cond_infr: "",
          },
        ],
      },
      submitted: false,
      err: false,
      numero: "",
      errors: [],
      isActive: true,
      isVisible: false,
    };
  },
  methods: {
    resetFields(boolean) {
      this.isActive = true;
      this.isVisible = false;
      this.submitted = boolean;
      this.numero = "";
    },
    async query() {
      try {
        this.submitted = false;
        this.errors = [];
        this.items = {};
        if (!this.numero) {
          this.items = {};
          this.submitted = false;
          return this.errors.push("Debe de ingresar un valor");
        }
        const location = await auth.getLocation();
        if (location) {
          this.isActive = false;
          this.isVisible = true;
          const tractasData = await tracta.getData(
            this.numero,
            location.latitud,
            location.longitud
          );
          this.resetFields(true);
          this.items = tractasData;
        } else {
          this.isActive = false;
          this.isVisible = true;
          const tractasData = await tracta.getData(this.numero);
          this.resetFields(true);
          this.items = tractasData;
        }
      } catch (err) {
        await statusUser.refresh(err.response.status);
        this.resetFields(false);
        if (err.response?.status == 504) {
          return this.errors.push(
            "En este momento, el sistema no se encuentra disponible. Estamos trabajando para solucionar este inconveniente lo antes posible."
          );
        }
        return this.errors.push(
          "No se encontraron resultados para el dominio. Verifica que los datos ingresados sean correctos o el vehículo podría no estar radicado en esta jurisdicción."
        );
      }
    },
    newQuery() {
      this.submitted = false;
      this.query = {};
    },
  },
};