import axios from "axios";
import conf from "./config";
axios.defaults.withCredentials = true;

// const getDataEndPoint = "http://localhost:5040/v1/api/acor/getData/";
//const headers = { ContentType: "application/json" };

export default {
  getData(patente, latitud = undefined, longitud = undefined) {
    if (latitud != undefined && longitud != undefined) {
      return axios
        .post(
          `${conf.url}${conf.prefix}acor/getData/${patente}`,
          {
            latitud: latitud,
            longitud: longitud,
          },
          { withCredentials: true }
        )
        .then((response) => response.data);
    } else {
      return axios
        .post(`${conf.url}${conf.prefix}acor/getData/${patente}`, {
          withCredentials: true,
        })
        .then((response) => response.data);
    }
  },
};
