import axios from "axios";
import conf from "./config";
axios.defaults.withCredentials = true;

// const getDataEndPoint = "http://localhost:5040/v1/api/tractas/getData/";

export default {
  getData(tracta, latitud = undefined, longitud = undefined) {
    if (latitud != undefined && longitud != undefined) {
      return axios
        .post(
          `${conf.url}${conf.prefix}tractas/getData/${tracta}`,
          {
            latitud: latitud,
            longitud: longitud,
          },
          { withCredentials: true }
        )
        .then((response) => response.data);
    } else {
      return axios
        .post(`${conf.url}${conf.prefix}tractas/getData/${tracta}`, {
          withCredentials: true,
        })
        .then((response) => response.data);
    }
  },
};
