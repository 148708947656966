import axios from "axios";
import conf from "./config";
axios.defaults.withCredentials = true;

export default {
  getData(sim, latitud = undefined, longitud = undefined) {
    if (latitud != undefined && longitud != undefined) {
      return axios
        .post(
          `${conf.url}${conf.prefix}sim/getData/${sim}`,
          {
            latitud: latitud,
            longitud: longitud,
          },
          { withCredentials: true }
        )
        .then((response) => response.data);
    } else {
      return axios
        .post(`${conf.url}${conf.prefix}sim/getData/${sim}`, {
          withCredentials: true,
        })
        .then((response) => response.data);
    }
  },
};
