<template>
  <!-- Header -->
  <div class="relative bg-blueGray-200 pb-32 pt-12 mt-3">
    <div class="container px-4 md:px-10 mx-auto w-full">
      <div>
        <h3 class="ml-3 mt-3" style="font-weight: 600">
          <p> A continuación podra visualizar desde que puntos de la ciudad cada
          usuario reliazo sus consultas, como también filtrar las mismas por
          rangos de fechas.</p>
         
        </h3>
        <!-- Card stats -->
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <select
              class="
                form-control
                border-0
                px-3
                mt-4
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              @change="onChange($event)"
            >
              <option disable value="">Listado de Usuarios</option>
              <option v-for="(data, index) in usersList" :key="index">
                {{ data.email }}
              </option>
            </select>
          </div>
          <div class="mt-4 w-full lg:w-6/12 xl:w-3/12 px-4">
            <datepicker
              v-model="date"
              multiDates
              @update:modelValue="handleDate"
            >
            </datepicker>
          </div>
        </div>
      </div>
    </div>
  </div> 
</template>

<script src="@/logic/scripts/headers">
</script>
