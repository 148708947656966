<template>
  <section class="relative w-full h-full py-10">
    <div class="container mx-auto px-4 h-full">
      <div
        class="flex content-center items-center justify-center h-full margin-bottom"
      >
        <div class="w-full px-4"> 
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
          >
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div class="text-blueGray-400 text-center mb-3 font-bold"></div>
              <form @submit.prevent="query">
                <div class="text-center w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Acor - Ingrese el dominio
                  </label>
                  <input
                    v-model="numero"
                    class=" text-center border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
                    placeholder="Patente número"
                    required
                  />
                </div>

                <div class="text-center mb-4 mt-6">
                  <button
                    @click="query"
                    class="bg-purple text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    v-if="isActive == true"
                  >
                    Consultar
                  </button>
                    <p v-if="errors.length">
                      <ul>
                        <li v-for="(error, indexError) in errors" :key="indexError"><b>{{ error }}</b></li>
                      </ul>
                    </p>
                    <div v-if=" isVisible == true" id="app">
                      <LoadingBar/>
                    </div>
                </div>
              </form>
            </div>
          </div>
          <div
            v-if="submitted &&  Object.keys(items).length != 0"
            class="container"
          >
            <div class="row">
              <div class="col">
                <div
                  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
                >
                  <div class=" bg-blueGray-200 rounded-t mb-0 px-4 py-3 border-0 text-center">
                    <div class="flex flex-wrap items-center">
                      <div
                        class="relative w-full px-4 max-w-full flex-grow flex-1"
                      >
                        <h3 class="font-semibold text-lg">Resultado de búsqueda:  {{ items.dominio }}</h3>
                      </div>
                    </div>
                  </div>
                  <div class="block w-full overflow-x-auto">
                    <!-- Projects table -->
                    <table
                      class="items-center w-full bg-transparent border-collapse"
                    >
                      <thead>
                        <tr>
                          <th
                            class="table-width  px-6 border-t-0  align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Tipo Documento
                          </th>
                           <td
                            class="border border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            <span class=" font-bold">
                              {{ items.tipo_doc }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="table-width  px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            CUIT
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.cuit }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="table-width  px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Razon
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.razsoc }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="table-width  px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Domicilio
                          </th>
                           <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.domicilio }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="table-width  px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                            Deuda
                          </th>
                           <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.deuda }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="table-width  px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Modelo
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.modelo_d }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="table-width  px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Motor
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.motor }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Chasis
                          </th>
                           <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.chasis }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Marca
                          </th>
                           <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.marca }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="table-width  px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Tipo
                          </th>
                             <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.tipo }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Modelo
                          </th>
                            <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.modelo }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Dominio
                          </th>
                             <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.dominio }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Fecha de inicio
                          </th>
                             <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.fec_inicio }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Fecha de baja
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.fec_baja }}
                          </td>
                        </tr>
                        <tr>
                           <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Radicado
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.radicado }}
                          </td>
                        </tr>
                        <tr>
                           <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Código del vehiculo
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.cod_vehiculo }}
                          </td>
                        </tr>
                        <tr>                           <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          >
                           Cilindrada
                          </th>
                           <td
                            class="border-t-0 border px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                          >
                            {{ items.cilindrada }}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>
</template>
<script src="@/logic/scripts/acor">
</script>
