<template>
  <section class="relative w-full h-full py-10">
    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full margin-bottom">
        <div class="w-full px-4">
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div class="text-blueGray-400 text-center mb-3 font-bold"></div>
              <form @submit.prevent="query">
                <div class="text-center w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                    Transporte - Ingrese la patente
                  </label>
                  <input type="tracta" v-model="numero"
                    class="text-center border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    placeholder="Patente número" required />
                </div>

                <div class="text-center mb-4 mt-6">
                  <button @click="query"
                    class="bg-purple text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button" v-if="isActive == true">
                    Consultar
                  </button>
                  <p v-if="errors.length">
                  <ul>
                    <li v-for="(error, indexError) in errors" :key="indexError"><b>{{ error }}</b></li>
                  </ul>
                  </p>
                  <div v-if="isVisible == true" id="app">
                    <LoadingBar />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div v-if="submitted" class="container">
            <div v-if="items.habilitacion.length" class="row">
              <div class="col" v-for="(data, index) in items.habilitacion" :key="index">
                <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
                  <div class="rounded-t mb-0 px-4 py-3 border-0 bg-blueGray-200 text-center">
                    <div class="flex flex-wrap items-center">
                      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3 class="font-semibold text-lg">Habilitación</h3>
                      </div>
                    </div>
                  </div>
                  <div class="block w-full overflow-x-auto">
                    <!-- Projects table -->
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead>
                        <tr v-if="data.num_hab">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            style="border-top-width: 0px">
                            Número
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            <span class="font-bold">
                              {{ data.num_hab }}
                            </span>
                          </td>
                        </tr>
                        <tr v-if="data.type">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Tipo
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.type }}
                          </td>
                        </tr>
                        <tr v-if="data.estado_hab">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Estado
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.estado_hab }}
                          </td>
                        </tr>
                        <tr v-if="data.fec_hab">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Fecha Habilitación
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.fec_hab }}
                          </td>
                        </tr>
                        <tr v-if="data.fec_vto_hab">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Fecha Vencimiento
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.fec_vto_hab }}
                          </td>
                        </tr>
                        <tr v-if="data.periodo">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Período
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle  border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.periodo }}
                          </td>
                        </tr>
                        <tr v-if="data.nro_lic">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Licencia número
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.nro_lic }}
                          </td>
                        </tr>
                        <tr v-if="data.nro_movil">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Móvil número
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle  border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.nro_movil }}
                          </td>
                        </tr>
                        <tr v-if="data.nombre_fantasia">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Nombre fantasía
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.nombre_fantasia }}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="block w-full overflow-x-auto">
                    <!-- Projects table -->
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead v-for="(chofer, index) in data.chofer" :key="index">
                        <tr v-if="chofer.apyn_ch">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Chofer
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ chofer.apyn_ch }}
                          </td>
                        </tr>
                        <tr v-if="chofer.fechanac_ch">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            style="border-top-width: 0px">
                            Fecha de nacimiento
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">

                            {{ chofer.fechanac_ch }}

                          </td>
                        </tr>
                        <tr v-if="chofer.dni_ch">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            DNI
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle  border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ chofer.dni_ch }}
                          </td>
                        </tr>
                        <tr v-if="chofer.domicilio_ch">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Domicilio
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle  border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ chofer.domicilio_ch }}
                          </td>
                        </tr>
                        <tr v-if="chofer.tel_f_ch">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Telefono
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ chofer.tel_f_ch }}
                          </td>
                        </tr>
                        <tr v-if="chofer.cel_ch">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Celular
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle  border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ chofer.cel_ch }}
                          </td>
                        </tr>
                        <tr v-if="chofer.correo_ch">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Correo
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ chofer.correo_ch }}
                          </td>
                        </tr>
                        <tr v-if="chofer.sexo_ch">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Sexo
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ chofer.sexo_ch }}
                          </td>
                        </tr>
                        <tr v-if="chofer.estado_chofer">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Estado chofer
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ chofer.estado_chofer }}
                          </td>
                        </tr>
                        <tr v-if="chofer.num_cut">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Número
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ chofer.num_cut }}
                          </td>
                        </tr>
                        <tr v-if="chofer.otoga_cut">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Desde
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ chofer.otoga_cut }}
                          </td>
                        </tr>
                        <tr v-if="chofer.vence_cut">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Hasta
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ chofer.vence_cut }}
                          </td>
                        </tr>
                        <tr v-if="chofer.seg_accidente_per">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Seguro
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ chofer.seg_accidente_per }}
                          </td>
                        </tr>
                        <tr v-if="chofer.estado_cut">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Estado
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ chofer.estado_cut }}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
              <div v-if="items.vehiculo.length" class="col">
                <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
                  <div class="rounded-t mb-0 px-4 py-3 border-0 bg-blueGray-200 text-center">
                    <div class="flex flex-wrap items-center">
                      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3 class="font-semibold text-lg">Vehículo</h3>
                      </div>
                    </div>
                  </div>
                  <div class="block w-full overflow-x-auto">
                    <!-- Projects table -->
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead v-for="(data, index) in items.vehiculo" :key="index">
                        <tr v-if="data.dominio_auto_local">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            style="border-top-width: 0px">
                            Dominio
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            <span class="ml-3 font-bold">
                              {{ data.dominio_auto_local }}
                            </span>
                          </td>
                        </tr>
                        <tr v-if="data.ano_fab">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Año
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.ano_fab }}
                          </td>
                        </tr>
                        <tr v-if="data.modelo">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Modelo
                          </th>
                          <td
                            class=" border border-t-0 border px-6 align-middle  border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.modelo }}
                          </td>
                        </tr>
                        <tr v-if="data.marca">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Marca
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.marca }}
                          </td>
                        </tr>
                        <tr v-if="data.num_motor">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Número motor
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.num_motor }}
                          </td>
                        </tr>
                        <tr v-if="data.num_chassis">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Número chasis
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.num_chassis }}
                          </td>
                        </tr>
                        <tr v-if="data.color">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Color
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.color }}
                          </td>
                        </tr>
                        <tr v-if="data.capacidad">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Capacidad
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.capacidad }}
                          </td>
                        </tr>
                        <tr v-if="data.domicilio">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Domicilio
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.domicilio }}
                          </td>
                        </tr>
                        <tr v-if="data.nom_rev_tecnica">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Nombre revisión
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.nom_rev_tecnica }}
                          </td>
                        </tr>
                        <tr v-if="data.fec_vto_rto">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Vencimiento revisión
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle  border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.fec_vto_rto }}
                          </td>
                        </tr>
                        <tr v-if="data.fec_poliza_auto">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Vencimiento poliza
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.fec_poliza_auto }}
                          </td>
                        </tr>
                        <tr v-if="data.est_vehi">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Estado vehículo
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.est_vehi }}
                          </td>
                        </tr>

                      </thead>
                    </table>
                  </div>
                </div>
              </div>
              <div v-if="items.titular.length" class="col">
                <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
                  <div class="rounded-t mb-0 px-4 py-3 border-0 bg-blueGray-200 text-center">
                    <div class="flex flex-wrap items-center">
                      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3 class="font-semibold text-lg">Titular</h3>
                      </div>
                    </div>
                  </div>
                  <div class="block w-full overflow-x-auto">
                    <!-- Projects table -->
                    <table class="items-center w-full bg-transparent border-collapse">
                      <thead v-for="(data, index) in items.titular" :key="index">
                        <tr v-if="data.tipo_doc">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            style="border-top-width: 0px">
                            Tipo Documento
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle  border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            <span class="font-bold">
                              {{ data.tipo_doc }}
                            </span>
                          </td>
                        </tr>
                        <tr v-if="data.cuit">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            CUIT
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.cuit }}
                          </td>
                        </tr>
                        <tr v-if="data.dni">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            DNI
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.dni }}
                          </td>
                        </tr>
                        <tr v-if="data.apyn">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Apellido y Nombre
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.apyn }}
                          </td>
                        </tr>
                        <tr v-if="data.domicilio_titular">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Domicilio
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.domicilio_titular }}
                          </td>
                        </tr>
                        <tr v-if="data.barrio_titular">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Barrio
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle order-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.barrio_titular }}
                          </td>
                        </tr>
                        <tr v-if="data.tel_titular">
                          <th
                            class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                            Teléfono
                          </th>
                          <td
                            class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {{ data.tel_titular }}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
              <div v-if="items.chofer && items.chofer.length" class="col">
                <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
                  <div class="rounded-t mb-0 px-4 py-3 border-0 bg-blueGray-200 text-center">
                    <div class="flex flex-wrap items-center">
                      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3 class="font-semibold text-lg">Chofer</h3>
                      </div>
                    </div>
                  </div>
                  <div class="block w-full overflow-x-auto">
                    <!-- Projects table -->
                    <table class="items-center w-full bg-transparent border-collapse">
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="items.infraccion.length" class="col">
              <div v-for="(data, index) in items.infraccion" :key="index"
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
                <div class="rounded-t mb-0 px-4 py-3 border-0 bg-blueGray-200 text-center">
                  <div class="flex flex-wrap items-center">
                    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                      <h3 class="font-semibold text-lg">Infracción N° {{ index + 1 }}</h3>
                    </div>
                  </div>
                </div>
                <div class="block w-full overflow-x-auto">
                  <!-- Projects table -->
                  <table class="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr v-if="data.acta_nro">
                        <th
                          class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                          style="border-top-width: 0px">
                          Número de acta
                        </th>
                        <td
                          class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                          <span class="ml-3 font-bold">
                            {{ data.acta_nro }}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="data.ano_fab">
                        <th
                          class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Fecha emisión
                        </th>
                        <td
                          class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                          {{ data.fecha_emi }}
                        </td>
                      </tr>
                      <tr v-if="data.lugar">
                        <th
                          class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Lugar
                        </th>
                        <td
                          class=" border border-t-0 border px-6 align-middle  border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                          {{ data.lugar }}
                        </td>
                      </tr>
                      <tr v-if="data.fecha_emi">
                        <th
                          class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Fecha
                        </th>
                        <td
                          class=" border border-t-0 border px-6 align-middle  border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                          {{ data.fecha_emi }}
                        </td>
                      </tr>
                      <tr v-if="data.inspector">
                        <th
                          class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Inspector
                        </th>
                        <td
                          class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                          {{ data.inspector }}
                        </td>
                      </tr>
                      <tr v-if="data.infraccion">
                        <th
                          class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Infracción
                        </th>
                        <td
                          class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                          {{ data.infraccion }}
                        </td>
                      </tr>
                      <tr v-if="data.secuestro">
                        <th
                          class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Secuestro
                        </th>
                        <td
                          class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                          {{ data.secuestro }}
                        </td>
                      </tr>
                      <tr v-if="data.dni_cond_infr">
                        <th
                          class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          DNI
                        </th>
                        <td
                          class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                          {{ data.dni_cond_infr }}
                        </td>
                      </tr>
                      <tr v-if="data.nom_cond_infr">
                        <th
                          class="table-width px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                          Nombre
                        </th>
                        <td
                          class="border-t-0 border px-6 align-middle border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                          {{ data.nom_cond_infr }}
                        </td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script src="@/logic/scripts/transporte">
</script>
