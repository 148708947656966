import LoadingBar from "@/components/LoadingBar.vue";
import sim from "@/logic/sim.ts";
import auth from "@/logic/auth.ts";
import * as status from "@/logic/helpers/funtions";
export default {
    components: {
        LoadingBar,
    },
    data() {
        return {
            items: {
                persona: [{
                    id_persona: "",
                    persona_tipo: "",
                    documento_tipo: "",
                    documento_nro: "",
                    nombre_raz_social: "",
                    nacionalidad: "",
                    ciudad_nacimiento: "",
                    provincia_nacimiento: "",
                    sexo_nacimiento: "",
                    genero: "",
                    fec_nac: "",
                    empresa_objetivo_principal: "",
                    estado_del_registro: "",
                    domicilios: [{
                        id_persona: "",
                        id_domicilio: "",
                        barrio: "",
                        calle: "",
                        altura: "",
                        piso: "",
                        depto: "",
                        adrema: "",
                        adrema_formateado: "",
                    }, ],
                    telefonos: [{
                        id_persona: "",
                        numero: "",
                        tipo_telefono: "",
                    }, ],
                }, ],
            },
            numero: "",
            submitted: false,
            errors: [],
            err: false,
            isActive: true,
            isVisible: false,
        };
    },
    methods: {
        resetFields(boolean = true) {
            this.isActive = true;
            this.isVisible = false;
            this.submitted = boolean;
            this.numero = "";
        },
        async query() {
            try {
                this.submitted = false;
                this.errors = [];
                this.items = {};
                if (!this.numero) {
                    this.items = {};
                    this.submitted = false;
                    return this.errors.push("Debe de ingresar un valor");
                }
                const location = await auth.getLocation();
                if (location) {
                    this.isActive = false;
                    this.isVisible = true;
                    const simData = await sim.getData(
                        this.numero,
                        location.latitud,
                        location.longitud
                    );
                    if (!simData) {
                        this.resetFields(false);
                        return this.errors.push(
                            "No se especificaron correctamente los parametros."
                        );
                    }
                    this.resetFields();
                    this.items = simData;
                } else {
                    this.isActive = false;
                    this.isVisible = true;
                    const simData = await sim.getData(this.numero);
                    this.resetFields();
                    this.items = simData;
                }
            } catch (err) {
                await status.refresh(err.response.status);
                this.isActive = true;
                this.isVisible = false;
                this.resetFields();
                if (err.response?.status == 504) {
                    return this.errors.push(
                      "En este momento, el sistema no se encuentra disponible. Estamos trabajando para solucionar este inconveniente lo antes posible."
                    );
                  }
                return this.errors.push(
                    "No se especificaron correctamente los parametros."
                );
            }
        },
        newQuery() {
            this.submitted = false;
            this.query = {};
        },
    },
};