<template>
  <div class="container mx-auto px-4 h-full margin-bottom">
    <div class="flex content-center items-center py-5 justify-center h-full">
      <div
        class="
          relative
          flex flex-col
          min-w-0
          break-words
          w-full
          mb-6
          shadow-lg
          rounded
        "
      ></div>
    </div>
    <div
      class="
        relative
        flex flex-col
        min-w-0
        break-words
        w-full
        mb-6
        shadow-lg
        rounded
      "
    >
      <div class="rounded-t mb-0 px-4 py-3 border-0 bg-blueGray-200">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-semibold text-lg">
              Consultas realizadas por los usuarios
            </h3>
          </div>
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="
                  px-6
                  align-middle
                  border border-solid
                  py-3
                  text-xs
                  uppercase
                  border-l-0 border-r-0
                  whitespace-nowrap
                  font-semibold
                  text-left
                "
              >
                Usuario
              </th>
              <th
                class="
                  px-6
                  align-middle
                  border border-solid
                  py-3
                  text-xs
                  uppercase
                  border-l-0 border-r-0
                  whitespace-nowrap
                  font-semibold
                  text-left
                "
              >
                Sistema
              </th>
              <th
                class="
                  px-6
                  align-middle
                  border border-solid
                  py-3
                  text-xs
                  uppercase
                  border-l-0 border-r-0
                  whitespace-nowrap
                  font-semibold
                  text-left
                "
              >
                Búsqueda
              </th>
              <th
                class="
                  px-6
                  align-middle
                  border border-solid
                  py-3
                  text-xs
                  uppercase
                  border-l-0 border-r-0
                  whitespace-nowrap
                  font-semibold
                  text-left
                "
              >
                Fecha
              </th>
              <th
                class="
                  px-6
                  align-middle
                  border border-solid
                  py-3
                  text-xs
                  uppercase
                  border-l-0 border-r-0
                  whitespace-nowrap
                  font-semibold
                  text-left
                "
              >
                Resultado
              </th>
            </tr>
          </thead>
          <tbody v-for="(data, index) in logs" :key="index">
            <tr>
              <td
                class="
                  border-t-0
                  px-6
                  align-middle
                  border-l-0 border-r-0
                  text-xs
                  whitespace-nowrap
                  p-4
                  text-left
                  flex
                  items-center
                "
              >
                <span class="ml-3 font-bold"
                  >{{ data.usuario.firstname }} {{ data.usuario.lastname }}
                </span>
              </td>
              <td
                class="
                  border-t-0
                  px-6
                  align-middle
                  border-l-0 border-r-0
                  text-xs
                  whitespace-nowrap
                  p-4
                "
              >
                <span class="ml-3 font-bold">{{ data.sistema }} </span>
              </td>
              <td
                class="
                  border-t-0
                  px-6
                  align-middle
                  border-l-0 border-r-0
                  text-xs
                  whitespace-nowrap
                  p-4
                "
              >
                <span class="ml-3 font-bold">{{ data.parametro }} </span>
              </td>
              <td
                class="
                  border-t-0
                  px-6
                  align-middle
                  border-l-0 border-r-0
                  text-xs
                  whitespace-nowrap
                  p-4
                "
              >
                <span class="ml-3 font-bold">{{ data.fecha }} </span>
              </td>
              <td
                class="
                  border-t-0
                  px-6
                  align-middle
                  border-l-0 border-r-0
                  text-xs
                  whitespace-nowrap
                  p-4
                "
              >
                <span class="ml-3 font-bold">{{ data.resultado }} </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="py-2" style="padding-left: 2%">
          <nav class="block">
            <ul class="flex pl-0 rounded list-none flex-wrap"  v-if="page <= pages.length">
              <li> 
              <a
              v-if="page != 1"
                @click="getLogs(page = 1)" 
                href="#"
                  class="
                    first:ml-0
                    text-xs
                    font-semibold
                    flex
                    w-8
                    h-8
                    mx-1 
                    p-0
                    rounded-full
                    items-center
                    justify-center
                    leading-tight
                    relative
                    border border-solid border-blueGray-500
                    bg-blueGray-500
                  "> 1
                  </a> 
                </li>
              <li
              >
                <a
                  href="#"
                  v-if="page != 1"
                  @click="getLogs(page = page - 1)"
                  class="
                    first:ml-0
                    text-xs
                    font-semibold
                    flex
                    w-8
                    h-8
                    mx-1 
                    p-0
                    rounded-full
                    items-center
                    justify-center
                    leading-tight
                    relative
                    border border-solid border-blueGray-500
                    bg-blueGray-500
                  "
                >
                  &lt &lt
                </a>
              </li>
              <li
              >
                <a
                  class="
                    first:ml-0
                    text-xs
                    flex
                    w-8
                    h-8
                    mx-1 
                    p-0
                    rounded-full
                    items-center
                    justify-center
                    leading-tight
                    relative
                    border border-solid border-blueGray-500
                    text-white
                    bg-purple
                  "
                >
                  {{page}}
                </a>
              </li>
              <li
              >
                <a
                  href="#"
                  v-if="page != pages.length"
                  @click="getLogs(page = page + 1)"
                  class="
                    first:ml-0
                    text-xs
                    font-semibold
                    flex
                    w-8
                    h-8
                    mx-1 
                    p-0
                    rounded-full
                    items-center
                    justify-center
                    leading-tight
                    relative
                    border border-solid border-blueGray-500
                    bg-blueGray-500
                  "
                >
                  >>
                </a>
              </li>
               <li> 
               <a href="#"
               v-if="page != pages.length"
               @click="getLogs(page = pages.length)"
                  class="
                    first:ml-0
                    text-xs
                    font-semibold
                    flex
                    w-8
                    h-8
                    mx-1 
                    p-0
                    rounded-full
                    items-center
                    justify-center
                    leading-tight
                    relative
                    border border-solid border-blueGray-500
                    bg-blueGray-500
                  "> {{pages.length}}</a> </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="@/logic/scripts/consultas"></script>