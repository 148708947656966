import auth from "@/logic/auth.ts";
import swall from "../helpers/swall";
export const refresh = async(status) => {
    try {
        if (status == 403) {
            await auth.reset();
            throw location.reload();
        }else if(status == 401) {
            await auth.reset();
            await swall.swallDinamic(
                "El tiempo de sesión expiró. Por favor inicie sesión nuevamente."
              );
            throw location.reload();
        }
        return;
    } catch (err) {
        throw err;
    }
};