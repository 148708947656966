import auth from "@/logic/auth.ts";
import swallHerlper from "@/logic/helpers/swall";
export default {
    name: "Login",
    data() {
        return {
            submitted: false,
            err: false,
            errors: [],
            email: "",
            password: "",
        };
    },
    methods: {
        async login() {
            try {
                this.errors = [];
                this.submited = false;
                if (!this.email || !this.password) {
                    this.submitted = false;
                    return this.errors.push("Debe de completar todos los campos");
                }
                const login = await auth.login(this.email, this.password);
                if (login.status == 401) {
                    return this.errors.push(login.data.error);
                } else if (login.status == 403) {
                    return this.errors.push(login.data.error);
                } else {
                    this.$router.push({
                        name: `${login.data[0]}`,
                    });
                }
            } catch (err) {
                this.err = true;
            }
        },
        async passowrdRecovery() {
            try {
                await swallHerlper.swallDinamic(
                    "Para recuperar su contraseña envíe un email a sistemas@ciudaddecorrientes.com.ar inidicando area a la que pertenece, cargo, teléfono o interno, apellido y nombre. Como motivo recuperar contraseña."
                );
            } catch (err) {
                throw err;
            }
        },
    },
    created() {
        window.scrollTo(0, 0);
        if (!("geolocation" in navigator)) {
            this.errorStr = "La geolocalización no esta disponible";
            return;
        }
        const success = async(position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            await auth.location(latitude, longitude);
        };

        const error = (err) => {
            console.log(err);
        };

        navigator.geolocation.getCurrentPosition(success, error);
    },
};