import acor from "@/logic/acor.ts";
import auth from "@/logic/auth.ts";
import LoadingBar from "@/components/LoadingBar.vue";
import * as status from "@/logic/helpers/funtions";
export default {
  components: {
    LoadingBar,
  },
  data() {
    return {
      items: {
        tipo_doc: "",
        cuit: "",
        razsoc: "",
        domicilio: "",
        deuda: "",
        modelo_d: "",
        motor: "",
        chasis: "",
        marca: "",
        tipo: "",
        modelo: "",
        dominio: "",
        fec_inicio: "",
        fec_baja: "",
        radicado: "",
        cod_vehiculo: "",
        cilindrada: "",
      },
      numero: "",
      submitted: false,
      errors: [],
      err: false,
      isActive: true,
      isVisible: false,
    };
  },
  methods: {
    resetFields(boolean = true) {
      this.isActive = true;
      this.isVisible = false;
      this.submitted = boolean;
      this.numero = "";
    },
    async query() {
      try {
        this.submitted = false;
        this.errors = [];
        this.items = {};
        if (!this.numero) {
          this.items = {};
          this.submitted = false;
          return this.errors.push("Debe de ingresar un valor");
        }
        const location = await auth.getLocation();
        if (location) {
          this.isActive = false;
          this.isVisible = true;
          const acorData = await acor.getData(
            this.numero,
            location.latitud,
            location.longitud
          );
          if (!acorData) {
            this.resetFields(false);
            return this.errors.push(
              "No se encontraron resultados para el dominio. Verifica que los datos ingresados sean correctos o el vehículo podría no estar radicado en esta jurisdicción."
            );
          }
          this.resetFields();
          this.items = acorData;
        } else {
          this.isActive = false;
          this.isVisible = true;
          const acorData = await acor.getData(this.numero);
          if (!acorData) {
            this.resetFields(false);
            return this.errors.push(
              "No se encontraron resultados para el dominio. Verifica que los datos ingresados sean correctos o el vehículo podría no estar radicado en esta jurisdicción."
            );
          }
          this.resetFields();
          this.items = acorData;
        }
      } catch (err) {
        await status.refresh(err.response.status);
        this.isActive = true;
        this.isVisible = false;
        this.resetFields();
        if (err.response?.status == 504) {
          return this.errors.push(
            "En este momento, el sistema no se encuentra disponible. Estamos trabajando para solucionar este inconveniente lo antes posible."
          );
        }
        return this.errors.push(
          "No se encontraron resultados para el dominio. Verifica que los datos ingresados sean correctos o el vehículo podría no estar radicado en esta jurisdicción."
        );
      }
    },
    newQuery() {
      this.submitted = false;
      this.query = {};
    },
  },
};
