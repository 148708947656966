<template>
  <div class="container mx-auto px-4 h-full margin-bottom">
     <div class="flex content-center items-center py-5  justify-center h-full">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
        <div
          class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="flex-auto  px-4 lg:px-10 pt-0">
            <div class="text-blueGray-400 text-center mb-3 font-bold"></div>
            <form>
            <div style="column-count: 2;">
                 <div class="relative mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Nombre 
                </label>
                <input
                  v-model="user.firstname"
                  required
                  class="w-full border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
                  placeholder="Nombre"
                  v-on:keypress="isLetter($event)"
                />
              </div>
               <div class="relative mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Apellido 
                </label>
                <input
                  v-model="user.lastname"
                  required
                  class="w-full border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
                  placeholder="Apellido"
                  v-on:keypress="isLetter($event)"
                />
              </div>
            </div>
            <div style="column-count: 2;">
               <div class="relative mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Area 
                </label>
                <input
                  v-model="user.area"
                  required
                  class="w-full border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
                  placeholder="Area"
                  v-on:keypress="isLetter($event)"
                />
              </div>
               <div class="relative mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Celular 
                </label>
                <input
                  v-model="user.phone"
                  type="number"
                  class="w-full border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
                  placeholder="Celular"
                />
              </div>
            </div>
              <div style="column-count: 2;">
                 <div class="relative mb-3">
                 <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Permisos
                </label>
                <Multiselect
                  v-model="value"
                  mode="multiple"
                  placeholder="Permisos"
                  :options="options"
                   @change="onChange($event)"
                >
                <template v-slot:multiplelabel="{ values }" >
                <div class="multiselect-multiple-label" >
                  <span v-for="(data, index) in values" :key="index" class="mr-2" style="color: rgb(194, 35, 110); font-weight: bold; font-size: small" > {{ data.value }}  </span>
                </div>
                </template>
              </Multiselect>
              </div>
                 <div class="relative mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email 
                </label>
                <input
                  type="email"
                  v-model="user.email"
                  required
                  class="w-full border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                  placeholder="Email"
                  v-on:keypress="validateEmail($event)"
                />
              </div>
            </div>
            <div style="column-count: 2;">
                 <div class="relative mb-4">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Tipo de usuario
                  </label>
                  <Multiselect
                      v-model="userType"
                      mode="multiple"
                      placeholder="Tipo de usuario"
                      :options="optionsUserType"
                      @change="onChangeUserType($event)"
                    >
                    <template v-slot:multiplelabel="{ values }" >
                    <div class="multiselect-multiple-label" >
                      <span v-for="(data, index) in values" :key="index" class="mr-2" style="color: rgb(194, 35, 110); font-weight: bold; font-size: small" > {{ data.value}}  </span>
                    </div>
                    </template>
                  </Multiselect>
                </div>
                <div  class="relative mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Contraseña
                </label>
                <input
                  type="password"
                  required
                  v-model="user.password"
                  class="w-full border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
                  placeholder="Contraseña"
                />
              </div>
          
              </div>
              <div style="column-count: 2;">
                <div class="relative mb-4">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Agentes a cargo
                    </label>
                  <Multiselect
                      v-model="agent"
                      mode="multiple"
                      placeholder="Agentes"
                      :options="optionsAgents"
                      @change="onChangeAgent($event)"
                    >
                    <template v-slot:multiplelabel="{ values }" >
                    <div class="multiselect-multiple-label w-full" >
                      <span v-for="(data, index) in values" :key="index" class="mr-2" style="color: rgb(194, 35, 110); font-weight: bold; font-size: small" > {{ data.value}}  </span>
                    </div>
                    </template>
                  </Multiselect>
              </div>
              <div class="relative mb-4">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Confirmar contraseña
                </label>
                <input
                  type="password"
                  required
                  class="w-full border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
                  placeholder="Confirmar contraseña"
                  v-model="user.passwordComfirm"
                />
              </div>
              </div>
              <div class="text-center mb-4 mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1  ease-linear transition-all duration-150"
                  type="button"
                  @click="query"
                  v-if="!edit"
                >
                  Crear Usuario
                </button>
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  @click="update"
                  v-if="edit"
                >
                  Editar Usuario
                </button>
                <p v-if="errors.length">
                    <ul>
                      <li v-for="(error, indexError) in errors" :key="indexError"><b>{{ error }}</b></li>
                    </ul>
                </p>
                  <p v-if="submited">
                    <ul>
                      <li ><b>Usuario registrado con éxito</b></li>
                    </ul>
                  </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    >
      <div class="rounded-t mb-0 px-4 py-3 border-0 bg-blueGray-200">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3 class="font-semibold text-lg">Listado de usuarios</h3>
          </div>
        </div>
      </div>
      <div  class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
            Email
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Permisos
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Editar
            </th>
              <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Activar/Desactivar
            </th>
             <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Estado
            </th>
             <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Ultimo Acceso
            </th>
          </tr>
          </thead>
          <tbody  v-for="(data, index) in users" :key="index" >
            <tr>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
              >
                <span class="ml-3 font-bold" >{{data.email}} </span>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <select  class="border-0  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow" style="width: -webkit-fill-available" aria-label="Default select example">
                  <option class="w-full" v-for="(dataType, index) in data.permission" :key="index">{{dataType}}</option>
                </select>
                
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <button @click="getOne(data._id)" style="color: orange; font-weight: bold">Editar</button>
                
              </td>
              <td 
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <button id="show-modal" v-if="data.isActive == true" @click="statusUser(false) && (userId = data._id)" style="color: #c2236e; font-weight: bold">Desactivar</button>
                 <button id="show-modal" v-if="data.isActive == false" @click="statusUser(true) && (userId = data._id)" style="color: green; font-weight: bold">Activar</button>
              </td>
              <td 
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <a v-if="data.isActive == true" class="mr-2" style="color: green; font-weight: bold">Activo</a> 
                <a v-if="data.isActive == false" class="mr-2" style="color: #c2236e; font-weight: bold">Inactivo</a> 
              </td> 
               <td 
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                 <span class="ml-3 font-bold" >{{ data.lastLogin}} </span> 
              </td>
            </tr>
          </tbody>
        </table>
        <div class="py-2" style="padding-left: 2%">
          <nav class="block">
            <ul class="flex pl-0 rounded list-none flex-wrap"  v-if="(page <= pages.length) || (page != 1)">
              <li
                v-for="pageNumber in pages"
                :key="pageNumber"
                @click="getAll(page = pageNumber)"
              >
                <a
                  href="#"
                  class="
                    first:ml-0
                    text-xs
                    font-semibold
                    flex
                    w-8
                    h-8
                    mx-1
                    p-0
                    rounded-full
                    items-center
                    justify-center
                    leading-tight
                    relative
                    border border-solid border-blueGray-500
                    bg-blueGray-500
                  "
                >
                  {{ pageNumber }}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="@/logic/scripts/register.js"></script>
<style src="@vueform/multiselect/themes/default.css"></style>